<template>
  <transition name="show">
    <div v-show="auctionId" class="box has-text-centered">
      <h1 class="title">{{ auctionId }}</h1>
      <div class="columns">
        <div class="column">
          <!-- <h1 class="subtitle">Timer</h1> -->
          <Auctiontimer :time-left-prop="100" :timelimit="300" />
          <!-- <h1 class="is-size-6 is-italic">Opstalverzekering</h1> -->
        </div>
        <div class="column">
           <!-- <div class="subtitle">Status</div> -->
          <div class="column">

          <div class="box"><p style="font-size: 0.7em">10EUR - NN</p></div>
          <div class="box"><p style="font-size: 0.7em">13EUR - AEGON</p></div>
          <div class="box"><p style="font-size: 0.7em">14EUR - OHRA</p></div>
          </div>
        </div>
        <div class="column">
          <!-- <h1 class="subtitle">Participation</h1> -->
          <div class="column has-text-centered">
            <b-field class="has-text-left" label="My offer">
              <b-numberinput type="is-link" v-model="offer"></b-numberinput>
            </b-field>

            <b-field class="has-text-left" label="Confirm offer">
              <b-numberinput
                type="is-link"
                v-model="confirmedOffer"
              ></b-numberinput>
            </b-field>
            <b-button
              :disabled="offer != confirmedOffer"
              icon-left="check"
              expanded
              type="is-primary"
              >Place offer</b-button
            >
          </div>
        </div>
      </div>
      <b-tabs expanded>
        <b-tab-item label="Client data" icon="home"
          ><div class="has-text-left">Client data placeholder</div></b-tab-item
        >
        <b-tab-item label="Auction data" icon="gavel"
          ><div class="has-text-left">Auction data placeholder</div></b-tab-item
        >
        <b-tab-item label="Pricing analytics" icon="chart-line"
          ><div class="has-text-left">Pricing placeholder</div></b-tab-item
        >

        <!-- <b-tab-item label="Videos" icon="video"></b-tab-item> -->
      </b-tabs>
      <div class="has-text-right">
        <b-button icon-left="times" type="is-warning " outlined inverted>Reject</b-button>
      </div>
    </div>
  </transition>
</template>

<script>
import Auctiontimer from './shared/auctiontimer.vue'

export default {
  props: {
    auctionId: {
      type: String,
      default: null
    }
  },
  name: 'auction',
  components: {
    Auctiontimer
  },
  data () {
    return {
      offer: 14,
      confirmedOffer: 12
    }
  }
}
</script>

<style lang="scss">
.tabs a {
  color: white;
}

// .tabs a:hover {
//     color: $light
// }
</style>
