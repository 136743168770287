<template>
  <div class="base-timer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
    >
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed"
          cx="50"
          cy="50"
          r="45"
        />
        <path
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        />
      </g>
    </svg>
    <span
      v-if="auctionIsActive"
      class="base-timer__label has-text-light"
    >{{ formattedTimeLeft }}</span>
    <span
      v-else
      class="base-timer__label has-text-light has-text-centered"
    >Deze veiling is afgelopen</span>
  </div>
</template>

<script>
const FULL_DASH_ARRAY = 283
const WARNING_THRESHOLD = 10 * 60
const ALERT_THRESHOLD = 5 * 60

const COLOR_CODES = {

  info: {
    color: 'green'
  },
  warning: {
    color: 'orange',
    threshold: WARNING_THRESHOLD
  },
  alert: {
    color: 'red',
    threshold: ALERT_THRESHOLD
  }
}

// const TIME_LIMIT = 3600;

export default {
  props: {
    timeLeftProp: {
      type: Number,
      default: 0
    },
    timelimit: {
      type: Number,
      default: 3600
    },
    // timePassedProp: {
    //   type: Number,
    //   default: 0,
    // },
    offers: {
      type: Number,
      default: 0
    },
    product: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      auctionIsActive: true,
      timeLeft: this.timeLeftProp,
      timerInterval: null
    }
  },
  computed: {
    // timePassed() {
    //     return this.timePassedProp + 0
    // },
    circleDasharray () {
      return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
    },

    formattedTimeLeft () {
      const timeLeft = this.timeLeft
      const minutes = Math.floor(timeLeft / 60)
      let seconds = timeLeft % 60

      if (seconds < 10) {
        seconds = `0${seconds}`
      }
      return `${minutes}:${seconds}`
    },

    // timeLeft() {
    //   return this.timeLimit - this.timePassed;
    // },

    timeFraction () {
      const rawTimeFraction = this.timeLeft / this.timelimit
      return rawTimeFraction - (1 / this.timelimit) * (1 - rawTimeFraction)
    },

    remainingPathColor () {
      const { info, warning } = COLOR_CODES
      if (this.timeLeft <= 1) {
        return warning.color
      } else { return info.color }
      // const { alert, warning, info } = COLOR_CODES
      // if (this.timeLeft <= alert.threshold) {
      //   return alert.color
      // } else if (this.timeLeft <= warning.threshold) {
      //   return warning.color
      // } else {
      //   return info.color
      // }
    }
  },

  watch: {
    timeLeft (newValue) {
      if (newValue === 0 || newValue < 0) {
        this.onTimesUp()
      }
    }
  },

  mounted () {
    this.startTimer()
  },

  methods: {
    onTimesUp () {
      this.auctionIsActive = false
      clearInterval(this.timerInterval)
      this.timeLeft = 0
    },

    startTimer () {
      if (this.timeLeft < 0) {
        this.auctionIsActive = false
      } else { this.timerInterval = setInterval(() => (this.timeLeft -= 1), 1000) }
    }

  }
}
</script>

<style scoped lang="scss">

.base-timer {
  position: relative;
  display: block;
  margin: auto;
  // width: 40%;
  max-width: 250px;

  &__svg {
    transform: scaleX(-1);
  }

  &__circle {
    fill: none;
    stroke: none;
  }

  &__path-elapsed {
    stroke-width: 8px;
    stroke: #003049;
  }

  &__path-remaining {
    stroke-width: 8px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;

    &.green {
      color: $green;
    }

    &.orange {
      color: $primary;
    }

    &.red {
      color: $link;
    }
  }

  &__label {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8em;
  }
}
</style>
