<template>
  <div class="card has-text-light">
    <header class="card-header">
      <div class="card-header-title is-centered">
        <slot name="iconheader" />
      </div>
      <p class="card-header-title is-centered has-text-light has-text-centered is-size-4">
        <slot name="header" />
      </p>
      <div class="card-header-title is-centered">
        <slot name="tagheader" />
      </div>
    </header>
    <div class="card-content ">
      <slot name="content" />
    </div>
    <footer class="card-footer">
      <p class="column is-12 has-text-centered has-text-light is-italic is-size-7">
        <slot name="footer" />
      </p>
    </footer>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex: auto;
}
</style>
