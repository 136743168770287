<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: function () {
        return {
          responsive: true,
          cutoutPercentage: 75,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: '% participating',
            position: 'bottom',
            fontColor: 'white'
          },
          legend: {
            display: false,
            position: 'right'
          },
          scales: {
            yAxes: [{
              ticks: {
                display: false,
                beginAtZero: true,
                fontColor: 'white'

              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [{
              ticks: {
                display: false,
                fontColor: 'white'
              },
              gridLines: {
                display: false
              }
            }]

          }

        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>
