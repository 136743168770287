<template>
    <section class="section">
     <h1 class="title">Open Auctions</h1>

      <div class="columns">
        <div class="column is-4">

          <ul>
             <transition-group appear name="fade">
            <li
              v-for="(auction, index) in openAuctions"
              @click="auctionChoice = auction.id"
              :key="auction.id"
              class="box mouseover"
              :class="{ green: auctionChoice == auction.id }"
              :style="{ '--index': index }"
            >

              <nav class="level">
                <div class="level-left">
                  <div class="level-item">{{ auction.id }}</div>
                   <div class="level-item">- {{ auction.product }}</div>

                </div>

                <div v-show="auctionChoice == auction.id" class="level-right">
                  <div class="level-item">
                    <b-icon icon="chevron-right"></b-icon>
                  </div>
                </div>
              </nav>
            </li>
                </transition-group>
          </ul>

        </div>
        <div class="column">
          <Auction :auction-id="auctionChoice" />
        </div>
      </div>
      <div class="has-text-left">
        <router-link class="is-italic" to="/dashboard">
          <b-icon
            @click.native="$router.push('/dashboard')"
            icon="chevron-left"
          ></b-icon>
          dashboard</router-link
        >
      </div>
    </section>
</template>

<script>
import Auction from '../components/auction.vue'
export default {
  name: 'openAuctions',
  components: {
    Auction
  },
  data: () => {
    return {

      auctionChoice: null,
      openAuctions: [
        { id: 'AuctionXyz123', product: 'HomeInsurance' },
        { id: 'AuctionXyz456', product: 'TravelInsurance' },
        { id: 'AuctionXyz894', product: 'CarInsurance' },
        { id: 'AuctionXyz749', product: 'PetInsurance' },
        { id: 'AuctionXyz659', product: 'CarInsurance' },
        { id: 'AuctionXyz184', product: 'ContentsInsurance' }
        // { id: 'AuctionXyz655', product: 'CarInsurance' }
      ]
    }
  },
  computed: {},
  mounted () {
    // this.renderChart(this.chartdata, this.options)
  }
}
</script>

<style lang="scss" scoped>
.green {
  background-color: $link;
}

.mouseover:hover {
  cursor: pointer;
}
.show {
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateX(60px);
  }
  &-enter-active,
  &-leave-active {
    transition: all 200ms;
  }
}
.fade {
  &-enter {
    opacity: 0;
    transform: translateX(60px);
  }
  &-enter-active {
    transition: all 300ms ease-in-out;
    transition-delay: calc(50ms * var(--index));
  }
}

// a {
//   color: $light
// }
</style>
