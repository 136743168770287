<template>
  <div class="">

    <b-table
      :data="data"
      ref="multiSortTable"
      :backend-sorting="backendSortingEnabled"
      @sort="sortPressed"
      @sorting-priority-removed="sortingPriorityRemoved"

      :sort-multiple="sortable"
      :sort-multiple-data="sortingPriority"
      :sort-multiple-key="customKey"
      :narrowed="narrowed"
      :paginated="paginated"
      :per-page=20
      :hoverable="hoverable"
      :selected.sync="selected"
      sort-icon="chevron-up"
      >

        <b-table-column  v-for="column in columns" :key="column" :field="column" :label="column" :sortable="sortable" v-slot="props">
            {{ props.row[column] }}
        </b-table-column>

    </b-table>
  </div>
</template>

<script>

export default {
  props: {
    inputData: {
      type: Array,
      default: null
    },
    columns: {
      type: Array,
      default: null
    },
    sortable: {
      type: Boolean,
      default: false
    },
    narrowed: {
      type: Boolean,
      default: false
    },
    paginated: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: null,
      customKey: null,
      backendSortingEnabled: false,
      multiColumnSortingEnabled: true,
      // data: [],
      sortingPriority: []
    }
  },
  methods: {
    // resetPriority () {
    //   this.$refs.multiSortTable.resetMultiSorting()

    //   // reset local backend sorting
    //   if (this.backendSortingEnabled) {
    //     this.sortingPriority = []
    //     this.loadAsyncData()
    //   }
    // },

    // Backend sorting
    sortingPriorityRemoved (field) {
      this.sortingPriority = this.sortingPriority.filter(
        (priority) => priority.field !== field)
      this.loadAsyncData(this.sortingPriority)
    },

    sortPressed (field, order, event) {
      if (this.backendSortingEnabled) {
        if (this.multiColumnSortingEnabled) {
          if ((this.customKey && event[this.customKey]) || !this.customKey) {
            const existingPriority = this.sortingPriority.filter(i => i.field === field)[0]
            if (existingPriority) {
              existingPriority.order = existingPriority.order === 'desc' ? 'asc' : 'desc'
            } else {
              // request sorted data from backend
              this.sortingPriority.push({ field, order })
            }
            this.loadAsyncData(this.sortingPriority)
          } else {
            // request regular sorted data from backend
            this.sortingPriority = [] // [{field, order}]
            this.loadAsyncData([{ field, order }])
          }
        }
      }
    }

    // "API request" for data
    // async loadAsyncData (sortingPriority = []) {
    //   const mockdata = JSON.parse(JSON.stringify(dataSource))
    //   // get data already sorted from the backend using sortingPriority
    //   this.data = orderBy(mockdata, sortingPriority.map(i => i.field), sortingPriority.map(i => i.order))
    // }
  },
  created () {
    this.data = this.inputData
  }
}
</script>

<style lang="scss">
// table {
//   background-color: rgba($color: #000000, $alpha: 0) !important;
//   color: $light !important
// }

</style>
